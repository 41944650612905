<template>
  <div class="flex items-center flex-col z-1 relative strike-modal">
    <div class="flex gap-2 mt-3">
      <div
        class="text-center flex flex-col justify-center items-center gap-1"
        v-for="day in days"
        :key="day"
      >
        <div
          class="w-5 h-5 rounded bg-primary500 flex items-center justify-center font-bold text-white"
        >
          <i class="fa-solid fa-check"></i>
        </div>
        <p class="font-bold text-xs">{{ $t(day) }}</p>
      </div>
    </div>

    <!-- <p class="text-center" v-if="id">{{ messages[strike % 6][lang] }}</p> -->
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from "vue";
import { getLocalTime } from "@/service/get-localtime.api";

const days = ref([]);

const props = defineProps(["comment"]);

const messages = [
  {
    uz: "Zo'r! Davom eting, har kun juda muhim!",
    ru: "Отлично! Продолжайте, каждый день имеет значение!",
  },
  {
    uz: "Ajoyib! Kichik qadamlar katta o'zgarishlarga olib keladi.",
    ru: "Замечательно! Маленькие шаги приводят к большим изменениям.",
  },
  {
    uz: "Zo'r! Endi siz kuchli odatni shakllantiryapsiz!",
    ru: "Отлично! Теперь вы формируете сильную привычку!",
  },
  {
    uz: "Davom eting! Shu ruhda davom eting!",
    ru: "Продолжайте! Держите этот настрой!",
  },
  {
    uz: "Ajoyib! Sizga hech narsa to'sqinlik qila olmaydi!",
    ru: "Замечательно! Ничто не сможет вас остановить!",
  },
  {
    uz: "Zo'r! Kuchli bo'ling va davom eting!",
    ru: "Отлично! Будьте сильны и продолжайте!",
  },
];

const calcDays = async () => {
  const response = await getLocalTime();
  const currentDate = new Date(response.data);
  const last5Days = [];

  // Determine how many days to show based on strike count
  const daysToShow = props.comment > 5 ? 5 : +props.comment;

  for (let i = 0; i < daysToShow; i++) {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() - i);
    const dayName = date.toLocaleDateString("en-US", {
      weekday: "short",
    });
    last5Days.unshift(dayName);
  }

  days.value = last5Days;
};

const localTime = ref("");

onMounted(async () => {
  localTime.value = await getLocalTime().then((res) => res.data);
  calcDays();
});

watch(
  () => props.comment,
  () => {
    calcDays();
  }
);
</script>
<style lang="scss">
.strike-modal {
  input {
    accent-color: #ef400f;
    &:disabled {
      opacity: 1;
      background-color: #ef400f !important;
    }
  }
}
</style>
