export default {
  namespaced: true,
  state() {
    return {
      isModal: false,
      isOpenBadgeDialog: false,
      isOpenCourseDialog: false,
    };
  },
  mutations: {
    open(state) {
      state.isModal = !state.isModal;
      if (state.isModal === true) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    openBadgeDialog(state) {
      state.isOpenBadgeDialog = !state.isOpenBadgeDialog;
    },
  },

  actions: {
    openBadgeDialog({ commit }) {
      commit("openBadgeDialog");
    },
    openCourseDialog(ctx) {
      ctx.state.isOpenCourseDialog = true;
    },
    closeCourseDialog(ctx) {
      ctx.state.isOpenCourseDialog = false;
    },
  },
};
