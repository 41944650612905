<template>
  <!-- Notification modal wrapper for the dialog -->
  <div class="notification-modal" v-if="type">
    <el-dialog
      v-model="dialogVal"
      class="w-[300px] rounded-md overflow-hidden"
      align-center
      @close="changeStatus"
    >
      <div class="flex items-center flex-col z-1 relative">
        <img v-if="type === 'coin'" src="@/assets/Coin.svg" alt="" width="80" />
        <div v-if="type === 'badge'">
          <img
            v-if="images?.length > 0"
            :src="mediaLink + images"
            alt="badge"
            width="80"
            height="80"
          />
          <img
            v-else
            src="@/assets/images/typing-rating/no-image.jpeg"
            alt="badge"
            width="80"
            height="80"
            class="rounded-xl"
          />
        </div>
        <img
          v-else-if="type === 'daily_streak'"
          src="@/assets/strike.svg"
          alt=""
          width="80"
        />

        <div>
          <p
            class="text-titleBlue text-xl font-bold text-center mt-5"
            v-if="lang === 'uz'"
          >
            {{ message_uz }} {{ comment }}
          </p>
          <p class="text-titleBlue text-xl font-bold text-center mt-5" v-else>
            {{ message_ru }} {{ comment }}
          </p>
        </div>

        <div v-if="type === 'daily_streak'">
          <StreakDays :comment="comment" />
        </div>
        <button
          @click="dialogVal = false"
          class="bg-primary500 w-full py-2 rounded-md text-white font-bold text-center mt-5"
        >
          {{ $t("space-shop.modal.btn") }}
        </button>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
const lang = localStorage.getItem("lang") || "uz";
import { ref, watch, onMounted } from "vue";
import playSound from "@/utils/playSound";
import renderConfetti from "@/utils/renderConfetti";
import apiV2 from "@/apiConfig/apiV2";
import { mediaLink } from "@/apiConfig/mediaLink";
import { ElMessage } from "element-plus";
import StreakDays from "@/components/NotificationModals/StreakDays.vue";

const props = defineProps([
  "dialogVal",
  "comment",
  "id",
  "type",
  "img",
  "isRead",
  "message_uz",
  "message_ru",
  "images",
]);
const emits = defineEmits(["update"]);

const dialogVal = ref(true);

onMounted(() => {
  playSound();
  renderConfetti();
});

watch(
  () => props.id,
  () => {
    playSound();
    renderConfetti();
  }
);

const changeStatus = async () => {
  await apiV2
    .patch(`/notification/${props.id}/read`, null, {
      params: {
        notification_id: props.id,
      },
    })
    .then(() => {
      emits("update");
      dialogVal.value = true;
    })
    .catch((err) => {
      ElMessage.error(err);
    });
};
</script>
