<template>
  <section class="leads-advertisement">
    <div class="leads-advertisement__header mb-2">
      <h1 class="capitalize">🎉 {{ $t("main.news") }}</h1>
    </div>
    <el-skeleton v-if="loading" animated class="mt-2">
      <template #template>
        <el-skeleton-item variant="image" style="width: 100%; height: 250px" />
      </template>
    </el-skeleton>
    <div class="leads-advertisement__body overflow-hidden" v-else>
      <swiper
        class="swiper-container"
        :pagination="{ clickable: true }"
        :navigation="true"
        :loop="true"
        :modules="adSwiperModules"
        :autoplay="{
          delay: 10000,
          disableOnInteraction: false,
        }"
      >
        <swiper-slide v-for="advertisement in ad" :key="advertisement.id">
          <router-link to="/mars-students-news">
            <img :src="mediaLink + advertisement.image" alt="" />
          </router-link>
        </swiper-slide>
      </swiper>
      <!-- <button
        v-if="!adForStudents"
        class="leads-advertisement__body-btn"
        @click="adDialog = !adDialog"
      >
        {{ $t("advertisement.join") }}
      </button> -->
    </div>
  </section>

  <el-dialog v-model="adDialog" class="ad-dialog !w-[500px] rounded-xl">
    <div class="ad-dialog__body">
      <img src="@/assets/ad-request.svg" alt="" class="mx-auto" />
      <h1>{{ $t("advertisement.dialog.header") }}</h1>
      <p>{{ $t("advertisement.dialog.description") }}</p>
      <button @click="adDialog = !adDialog">
        {{ $t("advertisement.dialog.confirm") }}
      </button>
    </div>
  </el-dialog>
</template>

<script>
import "./style/style.css";
import { onBeforeMount, ref, computed } from "vue";
import { getAd } from "./api/get-ad";
import { mediaLink } from "@/apiConfig/mediaLink";
import { Pagination, Autoplay, Navigation } from "swiper";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper } from "vue-awesome-swiper";

export default {
  components: {
    Swiper,
  },
  setup() {
    const ad = ref(null);
    const adDialog = ref(false);
    const loading = ref(false);

    const getAdvertisements = async () => {
      loading.value = true;
      ad.value = await getAd()
        .then((res) => res.data)
        .catch(() => []);
      loading.value = false;
    };

    onBeforeMount(async () => {
      await getAdvertisements();
    });

    const adForStudents = computed(() => {
      if (!ad.value) return false;
      return ad.value.some(
        (advertisement) => advertisement.for_whom === "student"
      );
    });

    return {
      ad,
      mediaLink,
      adSwiperModules: [Pagination, Autoplay, Navigation],
      adDialog,
      adForStudents,
      loading,
    };
  },
};
</script>
