<template>
  <main>
    <section class="shop container">
      <payment-alert />

      <div class="shop-header">
        <h1 class="shop-heading">{{ $t("space-shop.shop") }}</h1>
        <router-link to="/space-shop/history" class="shop-history-link">{{
          $t("space-shop.purchase-history")
        }}</router-link>
      </div>
      <div class="shop-items-wrapper">
        <SpaceShopSkeleton v-if="loading" />
        <div v-else-if="products?.length">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="8" :lg="6">
              <div class="bg-white rounded-[16px] p-4 mb-[30px]">
                <img
                  src="@/assets/sticker-pack.png"
                  alt=""
                  class="w-full h-[180px] mx-auto rounded-[16px] object-cover"
                />
                <p class="text-center mt-1">Blog uchun Sticker</p>
                <div class="flex items-center justify-center gap-4">
                  <div class="flex justify-center my-1 items-center gap-1">
                    <img
                      src="@/assets/images/typing-rating/quvvat.png"
                      alt=""
                      class="w-5 h-5 object-contain"
                    />
                    <p class="text-lg font-bold">1400</p>
                  </div>
                  <div class="flex justify-center my-1 items-center gap-1">
                    <img
                      src="@/assets/Coin.svg"
                      alt=""
                      class="w-5 h-5 object-contain"
                    />
                    <p class="text-lg font-bold">100</p>
                  </div>
                </div>
                <p class="text-center text-sm text-gray-500">Tez kunda</p>
              </div>
            </el-col>
            <el-col
              :xs="24"
              :sm="12"
              :md="8"
              :lg="6"
              v-for="product in products"
              :key="product.name"
            >
              <purchase-product
                :name="product.name"
                :price="product.cost"
                :discount="product.discount_price"
                :quantity="product.quantity"
                :img="mediaLink + product.image"
                :product_id="product.id"
                :preorder="product.preorder"
                :is_lead="!store.state.student.studentInfo?.is_client"
                :balance="store.state.student.studentInfo?.balance"
              ></purchase-product>
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <h1>Товары отсутствуют</h1>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import PurchaseProduct from "./components/PurchaseProduct.vue";
import SpaceShopSkeleton from "./components/SpaceShopSkeleton.vue";
import { getProducts } from "./api/get-products.api";
import { mediaLink } from "@/apiConfig/mediaLink";
import PaymentAlert from "@/components/PaymentAlert.vue";
export default {
  components: {
    PurchaseProduct,
    PaymentAlert,
    SpaceShopSkeleton,
  },
  setup() {
    const store = useStore();
    const products = ref(null);
    const mediaLinkValue = mediaLink;
    const loading = ref(false);

    const fetchProducts = async () => {
      let branchId = null;
      if (!store.state.student.studentInfo?.is_client) {
        branchId = 3;
      } else {
        branchId = store.state.student.studentInfo?.company_id;
      }
      loading.value = true;
      if (branchId) {
        products.value = await getProducts(branchId)
          .then((res) => res.data)
          .catch(() => []);
      }
      loading.value = false;
    };

    onMounted(() => {
      fetchProducts();
    });
    watch(
      () => store.state.student.studentInfo,
      () => {
        fetchProducts();
      }
    );

    return {
      products,
      mediaLink: mediaLinkValue,
      loading,
      store,
    };
  },
};
</script>

<style lang="scss" scoped>
.shop {
  margin-top: 30px;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-heading {
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    color: #0e0d5d;
    padding-left: 46px;

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      background-image: url("../../assets/shopping-cart-icon.png");
      width: 34px;
      height: 34px;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &-history-link {
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-align: right;
    color: #f54336;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      left: -46px;
      top: 0px;
      width: 34px;
      height: 34px;
      background-image: url("@/assets/space-shop-history-red.svg");
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  &-items-wrapper {
    margin-top: 27px;
  }
}
@media (max-width: 1200px) {
  .shop-items-wrapper {
    justify-content: start;
    gap: 10px;
  }
}
@media (max-width: 470px) {
  .shop-heading {
    font-size: 18px;
    padding-left: 40px;
    &::before {
      width: 20px;
      height: 20px;
      left: 10px;
      top: 0px;
    }
  }
  .shop-history-link {
    font-size: 18px;
    &::before {
      left: -22px;
      width: 20px;
      height: 20px;
      top: 4px;
    }
  }
}
</style>
