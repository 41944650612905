<template>
  <div class="streak-modal">
    <el-popover
      placement="bottom-end"
      trigger="click"
      width="auto"
      popper-style="padding:0"
    >
      <template #reference>
        <button
          @click="dialogVisible = true"
          class="flex items-center gap-1 border border-solid border-grayScale200 px-3 py-1 rounded-lg"
        >
          <span class="text-2xl text-deepOrange font-[700]">
            {{ studentInfo?.daily_streak?.streak_count }}
          </span>

          <img
            src="@/assets/strike.svg"
            alt=""
            class="w-6 h-7 object-contain"
          />
        </button>
      </template>

      <div class="w-full md:w-[469px] h-full p-2 md:p-6">
        <p class="text-2xl font-[700] text-[#333333]">Streak</p>
        <p class="font-[500] text-grayScale500">
          {{ $t("use-space") }}
        </p>
        <div
          class="bg-grayScale50 py-6 px-4 rounded-xl mt-4 flex justify-between"
        >
          <div
            v-for="(day, index) in weekDays"
            :key="index"
            class="text-center"
          >
            <template v-if="shouldShowStreak(day)">
              <img
                src="@/assets/strike.svg"
                alt=""
                class="w-6 h-6 md:w-12 md:h-12 object-contain"
              />
            </template>
            <template v-else>
              <div
                class="w-6 h-6 md:w-12 md:h-12 rounded-full bg-grayScale200"
              ></div>
            </template>
            <p class="text-darkBlue font-[700] text-[20px] mt-1">
              {{ $t(day) }}
            </p>
          </div>
        </div>

        <div
          class="flex gap-4 items-start border-2 border-solid border-grayScale200 p-4 rounded-xl mt-4"
        >
          <img
            src="@/assets/images/streak-365.png"
            alt=""
            class="w-[57px] object-contain h-16"
          />
          <div>
            <p class="text-lg font-[700] text-[#333333]">
              {{ $t("to-get-badge") }}
            </p>
            <p class="font-[500] text-grayScale500">
              {{ $t("streak-suptitle") }}
            </p>
          </div>
        </div>
        <div class="text-center mt-4">
          <button
            @click="openBadgeDialog"
            class="bg-deepOrange font-[700] text-lg mx-auto text-white px-4 py-2 w-[285px] rounded-xl"
          >
            {{ $t("in-detail") }}
          </button>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script setup>
import store from "@/store";
import { computed, ref } from "vue";

const studentInfo = computed(() => store.state.student.studentInfo);
const dialogVisible = ref(false);

const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const getCurrentDayIndex = () => {
  const today = new Date().getDay(); // 0 = Sunday, 1 = Monday, ...
  return today === 0 ? 6 : today - 1; // Convert to our array index (0 = Monday)
};

const shouldShowStreak = (day) => {
  const currentDayIndex = getCurrentDayIndex();
  const dayIndex = weekDays.indexOf(day);
  const streakCount = studentInfo.value?.daily_streak?.streak_count || 0;

  if (streakCount >= 7) {
    return true;
  }

  return (
    dayIndex <= currentDayIndex && dayIndex > currentDayIndex - streakCount
  );
};

const openBadgeDialog = () => {
  store.dispatch("modal/openBadgeDialog");
};
</script>
