<template>
  <div class="profile-section__games mt-5 h-[110px]">
    <div class="profile-section__games__right">
      <p class="text-white text-[18px] font-[700] leading-[19.8px]">
        {{ $t("typing") }}
      </p>
      <p class="text-[#FFFFFF99] text-[14px] font-[600] mb-2 leading-[19.6px]">
        {{ $t("get-more-power") }}
      </p>
      <div class="flex">
        <router-link
          to="/mars-game"
          class="bg-[#FFFFFF29] font-[600] text-white py-1 px-3 flex items-center gap-1 rounded-[6px]"
          >{{ $t("power-up-btn") }}
          <img
            src="@/assets/images/typing-rating/quvvat.png"
            alt=""
            class="h-4 object-contain"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup></script>
<style lang="scss">
.profile-section__games {
  background-image: url("@/assets/typing-banner.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  padding: 16px;
  border-radius: 16px;
}
</style>
