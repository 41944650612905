<template>
  <section class="rating">
    <StudentRatingFilter
      @ratingTimeChanged="ratingTime = $event.time"
      @ratingTypeChanged="ratingType = $event.type"
    />
    <div class="rating-top-animation">
      <div class="container">
        <div class="rating-top-animation-left" v-if="ratingTime">
          <h2 class="rating-top-animation__heading">
            {{ $t(`main.rating.sort.${ratingTime}`) }}
          </h2>
          <p class="rating-top-animation__text">
            {{ $t(`main.rating.text.${ratingTime}`) }}
          </p>
        </div>
        <div class="rating-top-animation-right">
          <div class="rating-top-animation__img-wrapper">
            <div
              class="rating-top-animation__imgs"
              v-for="item in 5"
              :key="item"
            >
              <template v-for="imgs in 2" :key="imgs">
                <img src=@/assets/logos/emoji/emoji1.png alt="" /> <img
                src=@/assets/logos/emoji/emoji2.png alt="" /> <img
                src=@/assets/logos/emoji/emoji3.png alt="" /> <img
                src=@/assets/logos/emoji/emoji4.png alt="" /> <img
                src=@/assets/logos/emoji/emoji5.png alt="" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <!-- clans -->
      <div
        class="grid gap-5 grid-cols-1 md:grid-cols:2 xl:grid-cols-3"
        v-if="ratingType == 2"
      >
        <div class="p-5 rounded-xl" v-for="clan in clans" :key="clan">
          <p
            class="text-xl text-center uppercase text-titleBlue font-bold mb-5"
          >
            {{ clan }}
          </p>
          <div
            v-for="student in ratingData[clan]?.students"
            :key="student.id"
            class="mb-2"
          >
            <StudentMiniCard
              :studentCoins="student?.coins"
              :studentName="`${student?.first_name} ${student?.last_name}`"
              :avatarLink="student?.avatar"
              :order="student?.order"
              :isMe="student?.me"
              :id="student?.id"
              :rank="student?.rank?.title"
            />
          </div>
        </div>
      </div>
      <div v-else class="pt-5">
        <div v-for="student in ratingData" :key="student.id" class="my-2">
          <StudentMiniCard
            :studentCoins="student?.coins"
            :studentName="`${student?.first_name} ${student?.last_name}`"
            :avatarLink="student?.avatar"
            :order="student?.order"
            :isMe="student?.me"
            :id="student?.id"
            :rank="student?.rank?.title"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { StudentRatingFilter } from "@/features";
import { getStudentsRating } from "./api/get-students-rating.api";
import { computed, ref, watch } from "vue";
import { DateTime } from "luxon";
import { StudentMiniCard } from "@/entities";
import "./style/student-rating.css";

const ratingType = ref(1);

const ratingTime = ref("");

const ratingData = ref([]);

const clans = ["coders", "gamers", "hackers"];

const changeRatingList = async () => {
  const { from_date, till_date } = calculateTime.value;
  const data = {
    from_date,
    till_date,
    filter: ratingType.value,
  };
  if (from_date && till_date) {
    const res = await getStudentsRating(data);
    ratingData.value = res?.data;
  }
};

watch(ratingTime, () => {
  changeRatingList();
});

watch(ratingType, () => {
  changeRatingList();
});

const calculateTime = computed(() => {
  const time = {};
  if (ratingTime.value === "daily") {
    time["from_date"] = DateTime.now().toFormat("yyyy-MM-dd");
    time["till_date"] = DateTime.now().toFormat("yyyy-MM-dd");
  } else if (ratingTime.value === "weekly") {
    time["from_date"] = DateTime.now().startOf("week").toFormat("yyyy-MM-dd");
    time["till_date"] = DateTime.now().endOf("week").toFormat("yyyy-MM-dd");
  } else if (ratingTime.value === "monthly") {
    time["from_date"] = DateTime.now().startOf("month").toFormat("yyyy-MM-dd");
    time["till_date"] = DateTime.now().endOf("month").toFormat("yyyy-MM-dd");
  }
  return time;
});
</script>
