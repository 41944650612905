<template>
  <div class="register-course-modal">
    <el-dialog
      v-model="dialogVisible"
      :title="$t('status-6')"
      width="400px"
      align-center
      class="rounded-xl"
      @close="store.dispatch('modal/closeCourseDialog')"
    >
      <div class="text-center">
        <img src="@/assets/images/x.png" alt="" class="mx-auto h-[70px] mt-5" />
        <p class="text-center text-lg my-5">
          {{ $t("register-course-desc-6") }}
        </p>

        <button
          @click="store.dispatch('modal/closeCourseDialog')"
          class="bg-primary500 text-white px-4 py-2 rounded-md mt-2"
        >
          {{ $t("understand") }}
        </button>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import store from "@/store";
import { computed } from "vue";
const dialogVisible = computed(() => store.state.modal.isOpenCourseDialog);
const studentInfo = computed(() => store.state.student.studentInfo);
</script>
