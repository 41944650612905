<template>
  <the-header v-show="!isMarsGame" @post-created="created" />
  <router-view :isCreated="newCreated" />
</template>

<script>
import { TheHeader } from "@/widgets";
import { ref, computed } from "vue";
import { useRoute } from "vue-router";

export default {
  components: { TheHeader },
  setup() {
    const route = useRoute();
    const newCreated = ref(false);

    const isMarsGame = computed(() => {
      return route.path.includes("/mars-game");
    });

    const created = () => {
      newCreated.value = !newCreated.value;
    };

    return {
      created,
      newCreated,
      isMarsGame,
    };
  },
};
</script>

<style></style>
