<template>
  <div
    @click="handleClick"
    class="flex items-center gap-2 mt-2 p-2 border-b border-gray-200"
    :class="{
      'bg-[#EFF8FF] rounded-md': !isRead,
    }"
  >
    <img
      v-if="type === 'coin'"
      src="@/assets/Coin.svg"
      alt=""
      class="w-[35px] h-[35px]"
    />
    <div v-if="type === 'badge'">
      <img
        v-if="images?.length > 0"
        :src="mediaLink + images"
        alt=""
        class="w-[35px] h-[35px] object-contain"
      />
      <img
        v-else
        src="@/assets/images/typing-rating/no-image.jpeg"
        alt=""
        class="w-[35px] h-[35px] rounded-full object-contain"
      />
    </div>

    <img
      v-else-if="type === 'daily_streak'"
      src="@/assets/strike.svg"
      alt=""
      class="w-[35px] h-[35px]"
    />
    <div>
      <p class="text-titleBlue text-sm font-bold mt-2">
        {{ lang === "uz" ? message_uz : message_ru }} {{ comment }}
      </p>

      <p class="text-[10px] text-[#9e9e9e]">
        {{ DateTime.fromISO(date).toFormat("dd-MMM-yyyy HH:mm") }}
      </p>
    </div>
  </div>
</template>
<script setup>
const lang = localStorage.getItem("lang") || "uz";
import { DateTime } from "luxon";
import { mediaLink } from "@/apiConfig/mediaLink";

const props = defineProps([
  "comment",
  "isRead",
  "date",
  "id",
  "type",
  "img",
  "title",
  "message_uz",
  "message_ru",
  "images",
]);
const emits = defineEmits(["update", "click"]);

const handleClick = () => {
  emits("click");
};
</script>
