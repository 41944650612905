<template>
  <header class="header bg-white">
    <div class="container header-wrapper">
      <div class="header-left">
        <router-link
          :to="router?.name != 'parent-main' ? '/main' : '/parent-main'"
        >
          <img
            class="header-left__logo"
            src="@/assets/mars-logo.png"
            alt="mars-logo"
          />
        </router-link>
      </div>
      <div class="header-center" v-if="router?.name != 'parent-main'">
        <router-link class="header-center__link" to="/main">
          <i class="fa-solid fa-house"></i>
          {{ $t("learning") }}
        </router-link>
        <router-link class="header-center__link" to="/blog">
          <i class="fa-solid fa-blog"></i>
          {{ $t("blog-title") }}
        </router-link>

        <router-link class="header-center__link" to="/space-shop">
          <i class="fa-solid fa-store"></i>

          {{ $t("mars-shop") }}
        </router-link>
      </div>
      <div class="header-right">
        <RegisterCourseModal />

        <button
          @click.prevent.stop="openPostModal"
          class="create-post"
          :disabled="!studentInfo?.is_client"
        >
          + {{ $t("header.header-create-post.post-btn") }}
        </button>
        <!-- <SurveysModal /> -->
        <StreakModal />
        <NotificationsCom
          v-if="studentInfo?.id"
          :student_id="studentInfo?.id"
        />

        <div v-popover="popoverRef">
          <profile-image
            :imageUrl="studentInfo?.avatar"
            size="small"
            :rank="studentInfo?.rank?.title"
          />
        </div>
      </div>
    </div>

    <el-popover
      ref="popoverRef"
      trigger="click"
      popper-class="header-right__popover-wrapper"
      popper-style="width: unset"
      placement="bottom-end"
    >
      <header-feature
        :student_name="studentInfo?.first_name + ' ' + studentInfo?.last_name"
        :imageUrl="studentInfo?.avatar"
        :rank="studentInfo?.rank?.title"
      />
    </el-popover>
    <el-dialog
      :title="$t('header.header-create-post.post-btn')"
      v-model="postModal"
      class="w-[500px] rounded-xl"
    >
      <create-post @close="created" :postLimit="Number(postLimit)" />
    </el-dialog>
  </header>
</template>

<script setup>
import { onMounted, ref, onBeforeMount, defineEmits, computed } from "vue";
import { ProfileImage } from "@/entities";
import { HeaderFeature } from "@/features";
import "./style/header.css";
import CreatePost from "@/features/CreatePost/CreatePost.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import NotificationsCom from "@/widgets/Notifications/NotificationsCom.vue";
import StreakModal from "@/components/StreakModal.vue";
import RegisterCourseModal from "@/components/RegisterCourseModal.vue";
// import SurveysModal from "@/components/SurveysModal.vue";
import apiV2 from "@/apiConfig/apiV2";

const emits = defineEmits(["post-created"]);

const router = useRoute();

const studentInfo = computed(() => store.state.student.studentInfo);
const store = useStore();
const postLimit = ref(null);
const getPostLimit = async () => {
  postLimit.value = await apiV2
    .get("/blogs/posts/daily/count")
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

onMounted(async () => {
  await getPostLimit();
});

const popoverRef = ref();

const postModal = ref(false);

const created = () => {
  postModal.value = !postModal.value;
  emits("post-created");
  getPostLimit();
};

onBeforeMount(async () => {
  await store.dispatch("getMe");
});

const openPostModal = () => {
  if (studentInfo.value?.status !== 5) {
    store.dispatch("modal/openCourseDialog");
    return;
  }

  postModal.value = true;
};
</script>
<style lang="scss">
@media (max-width: 992px) {
  .create-post {
    display: none;
  }
}
</style>
