<template>
  <div
    v-if="imageUrl?.length"
    class="rounded-full profile-img relative flex items-center justify-center"
    :class="[
      rank,
      {
        'w-[150px] h-[150px] !p-[11px] large': size === 'large',
        'w-[48px] h-[48px]': size === 'small',
        'w-[90px] h-[90px]': size === 'medium',
      },
    ]"
  >
    <el-image
      v-if="preview"
      style="
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #eee;
      "
      :src="mediaLink + imageUrl"
      :zoom-rate="1.2"
      :max-scale="7"
      :min-scale="0.2"
      :initial-index="4"
      fit="contain"
      :preview-src-list="[mediaLink + imageUrl]"
      lazy
    />
    <img
      v-else
      :src="mediaLink + imageUrl"
      alt="avatar"
      class="w-full h-full rounded-full object-contain user-img"
    />
    <button
      v-if="canChange"
      class="absolute -bottom-5 left-1/2 -translate-x-1/2"
      @click="openAvatarDialog"
    >
      <img src="@/assets/avatar-replace.svg" alt="" />
    </button>
  </div>
  <div
    v-else
    class="rounded-full profile-img relative"
    :class="[
      rank,
      {
        'w-[150px] h-[150px]': size === 'large',
        'w-[48px] h-[48px]': size === 'small',
        'w-[90px] h-[90px]': size === 'medium',
      },
    ]"
  >
    <img
      src="@/assets/default-user-img.jpg"
      alt="avatar"
      class="w-full h-full rounded-full user-img"
    />
    <button
      v-if="canChange"
      class="absolute -bottom-5 left-1/2 -translate-x-1/2"
      @click="openAvatarDialog"
    >
      <img src="@/assets/avatar-replace.svg" alt="" />
    </button>
  </div>

  <el-dialog
    v-model="avatarDialog"
    width="400px"
    align-center
    :title="$t('change-avatar')"
  >
    <AvatarDialog @closeModal="avatarDialog = false" :student_id="student_id" />
  </el-dialog>
</template>

<script setup>
import { mediaLink } from "@/apiConfig/mediaLink";
import AvatarDialog from "@/components/AvatarDialog.vue";

import { ref, defineProps } from "vue";

const props = defineProps([
  "imageUrl",
  "canChange",
  "student_id",
  "size",
  "rank",
  "preview",
]);

const avatarDialog = ref(false);

const openAvatarDialog = () => {
  avatarDialog.value = true;
};
</script>

<style lang="scss">
.profile-img {
  padding: 3px;
  background-color: #505b65;
  &.large {
    box-shadow: 0px 4px 2px 0px #00000033;
  }
  .user-img {
    background-color: white;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
  }
  &.Iron {
    background-color: #505b65;
  }

  &.Bronze {
    background-color: #c26e2a;
  }

  &.Silver {
    background-color: #96acca;
  }

  &.Gold {
    background-color: #fdc61d;
  }

  &.Platinum {
    background: radial-gradient(
      72.92% 72.92% at 17.71% 12.5%,
      #f4c287 0%,
      #e12bee 36%,
      #5744ec 70.5%,
      #27bedb 100%
    );
  }
  &.Marsianin {
    background: radial-gradient(
        72.92% 72.92% at 17.71% 12.5%,
        #01d0fd 0%,
        #0e2b52 54.6%,
        #0e2b52 74.34%,
        #c85f0c 74.5%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  }
}
</style>
