<template>
  <div v-if="loading" class="bg-white h-[100px] w-full p-2 rounded-lg">
    <el-skeleton :rows="1" animated />
  </div>
  <div v-else class="bg-white h-[100px] w-full p-2 rounded-xl">
    <p class="font-bold text-[18px] text-darkBlue">
      {{ $t("additional-lesson") }}
    </p>
    <table class="w-full mt-1">
      <thead>
        <tr>
          <th v-for="day in weekDays" :key="day.date" class="">
            <div class="flex flex-col items-center">
              <span class="text-[#424242] text-[14px] font-[600]">
                {{ $t(day.day) }}
              </span>
              <button
                v-if="checkLessonStatus(day.date) === 'open'"
                @click="selectDay(day.date)"
                class="text-md mt-2 hover:bg-gray-100 w-6 h-6 flex items-center justify-center rounded-md"
              >
                <i class="fa-solid text-md fa-plus text-[#E0E0E0]"></i>
              </button>
              <button
                @click="selectDay(day.date)"
                v-else-if="checkLessonStatus(day.date) === 'not_came'"
                class="text-md mt-2 hover:bg-gray-100 text-gray-500 w-6 h-6 flex items-center justify-center rounded-md"
              >
                <span
                  class="bg-[#f5f5f5] text-[14px] rounded px-1 py-0.5 text-black"
                  >{{ getLessonHours(day.date) }}</span
                >
              </button>
              <button
                @click="selectDay(day.date)"
                v-else-if="checkLessonStatus(day.date) === 'canceled'"
                class="text-md mt-2 hover:bg-gray-100 text-red-500 w-6 h-6 flex items-center justify-center rounded-md"
              >
                <i class="fa-solid text-md fa-circle-xmark"></i>
              </button>
              <button
                @click="selectDay(day.date)"
                v-else-if="checkLessonStatus(day.date) === 'came'"
                class="text-md mt-2 hover:bg-gray-100 text-green-500 w-6 h-6 flex items-center justify-center rounded-md"
              >
                <i class="fa-solid text-md fa-circle-check"></i>
              </button>
            </div>
          </th>
        </tr>
      </thead>
    </table>
  </div>
  <el-dialog
    align-center
    v-model="bookingModal"
    :title="$t('additional-lesson')"
    class="w-[500px] rounded-lg"
  >
    <book-tutor-2
      :day="selectedDay"
      :lessonStatus="lessonStatus"
      :tutor="selectedTutor"
      :slot="selectedSlot"
      :theme="selectedTheme"
      :id="selectedId"
      :availableLessonsDates="availableLessonsDates"
      @close-modal="(bookingModal = false), getExtraLessons()"
    />
  </el-dialog>
</template>

<script setup>
import { getBookInfo } from "./api/get-book-info.api";
import { onMounted, ref, watch, computed } from "vue";
import { DateTime } from "luxon";
import { useStore } from "vuex";
import BookTutor2 from "@/features/BookTutor/BookTutor2.vue";

const bookingModal = ref(false);
const store = useStore();
const loading = ref(false);
const selectedDay = ref(null);
const lessonStatus = ref(null);

const weekDays = ref([]);
const studentInfo = computed(() => store.state.student.studentInfo);

function getSevenDays() {
  const today = DateTime.now();
  for (let i = 0; i <= 6; i++) {
    const date = today.plus({ days: i });
    if (date.weekday !== 7) {
      weekDays.value.push({
        date: date.toFormat("yyyy-MM-dd"),
        day: date.toFormat("EEE"),
      });
    }
  }

  getExtraLessons();
}

const lessons = ref([]);

const getExtraLessons = async () => {
  if (!studentInfo.value?.id) {
    return;
  }
  loading.value = true;
  lessons.value = await getBookInfo(
    weekDays.value[0].date,
    weekDays.value[weekDays.value.length - 1].date,
    studentInfo.value.id
  )
    .then((res) => {
      return res.data;
    })
    .catch(() => []);

  checkAvailableLessonDates();
  loading.value = false;
};

watch(studentInfo, () => {
  getExtraLessons();
});

onMounted(async () => {
  getSevenDays();
});

const selectDay = (day) => {
  if (studentInfo.value?.status !== 5) {
    store.dispatch("modal/openCourseDialog");
    return;
  }
  selectedDay.value = day;
  lessonStatus.value = checkLessonStatus(day);
  selectLessonDetails(day);
  bookingModal.value = true;
};
const selectedTutor = ref(null);
const selectedSlot = ref(null);
const selectedTheme = ref(null);
const selectedId = ref(null);

function selectLessonDetails(for_date) {
  const lesson = lessons.value.find((lesson) => lesson.for_date === for_date);

  if (lesson?.data?.length) {
    selectedTutor.value = lesson?.data[0]?.tutor?.id;
    selectedSlot.value = lesson?.data[0]?.slot?.id;
    selectedTheme.value = lesson?.data[0]?.theme;
    selectedId.value = lesson?.data[0]?.id;
  } else {
    selectedTutor.value = null;
    selectedSlot.value = null;
    selectedTheme.value = null;
    selectedId.value = null;
  }
}
const availableLessonsDates = ref([]);
function checkLessonStatus(for_date) {
  const lesson = lessons.value.find((lesson) => lesson.for_date === for_date);

  if (lesson?.data?.length) {
    return lesson?.data[0]?.status;
  }
  return "open";
}

function checkAvailableLessonDates() {
  availableLessonsDates.value = [];
  lessons.value.forEach((lesson) => {
    if (!lesson?.data?.length) {
      availableLessonsDates.value.push(lesson?.for_date);
    }
  });
}

function getLessonHours(for_date) {
  const lesson = lessons.value.find((lesson) => lesson.for_date === for_date);
  return lesson?.data[0]?.slot?.from_hour?.slice(0, 5);
}
</script>

<style></style>
